<template>
  <!-- :class="{ '!table-auto': _props._options.auto }" -->
  <table class="nx-table all-table leading-sm bottom-0 h-fit w-full table-fixed rounded-lg">
    <slot :columns="columns" name="header" :formatLabel="options.formatLabel" :options="options">
      <tr class="table-header font-bold">
        <th class="table-header-cell px-2 py-1" v-for="col in columns" :key="col">
          <div v-if="col !== options.x" v-html="options.formatLabel(col)"></div>
        </th>
      </tr>
    </slot>
    <tr class="table-row rounded-lg" v-for="(line, idx) in tableData" :key="idx">
      <td
        class="table-cell overflow-hidden whitespace-nowrap px-2 py-1"
        v-for="col in columns"
        :key="col"
        v-html="options.formatY(line[col])"
      ></td>
    </tr>
  </table>
</template>
<script setup lang="ts">
import { getUniqueCategories, pivotData } from './data-utils'
const { data, options } = defineProps(['data', 'options'])
const categories = getUniqueCategories(data, options)
const tableData = pivotData(data, options, categories)
const columns = Object.keys(tableData[0]).filter(k => k !== 'group')
</script>
<style scoped>
.nx-table .nx-table-row:nth-child(even) {
  background-color: rgb(var(--primary), 0.1);
}
</style>
