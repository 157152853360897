<template lang="pug">
h1 {{ headerText }}
  button(style="visibility:hidden")
  button.primary(@click="openStudio" style="margin-left: 8px;") {{ t.open_studio || 'Open studio' }}
subtitle(style="font-style: italic; margin-top: -5px;") {{ isNew ? t.data_report_page.subtitle.new : t.data_report_page.subtitle.edit}}
.block.expand(key="form" v-if="loading === false" )
  .column
    .row(v-if="isNew")
      .column
        label.mb2 {{ t.name || 'name' }}
        input(style="width: 400px" type="text" :placeholder="t.name" v-model="name")
    .row.mt4
      .column(v-if="isNew")
        label.mb2 {{ t.data_report_page.specialty.label }}
        select(v-model="specialtyName" @change="changeSpecialty")
          option(value="" disabled) {{ t.data_report_page.specialty.placeholder }}
          option(v-for="s in specialties" :value="s.name") {{ s.name }}
      template(v-else)
        label.mb2 {{ t.data_report_page.specialty.edit }} {{ specialtyName }}

    label.mt4.mb2 {{ t.data_report_page.query || 'Query' }}
    code-editor(:code="query" max-height="calc(100vh - 660px)" @update="handleQueryChanges")

    label.mt4.mb2 {{ t.data_report_page.variables || 'Variables' }}
      tooltip-helper(v-if="staticVariables.length" )
        template(#icon="{ updateDisplay }")
          span.ml2.static-tooltip(@mouseover="updateDisplay" @mouseleave="updateDisplay") {{staticVariablesText}}
        div.static-tooltip-text(v-for="name in staticVariables" :key="name")
          div {{name}}
    code-editor(:code="variables" max-height="200px" @update="handleVariableChanges")

  .row.mt4
    button.mr2.primary(v-if="!isDataReportProduct || this.$route.query.new" :disabled="!hasUnsavedChanges" @click="save") {{ t.save }}
    button.mr2.secondary(@click="$router.push($root.appath + 'data-reports')") {{ t.cancel }}
    button.mr2.primary(v-if="!isNew && !hasUnsavedChanges" @click="$root.$router.push({ path: $root.appath + 'data-report-run', query: { id } })") {{ t.run }}
</template>

<script>
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import specialtyService from '@100-m/hauru/src/services/SpecialtyService'

export const additions = {}
export default {
  data() {
    return {
      loading: true,
      queries: [],
      variables: '{}',
      id: 0,
      name: '',
      query: '',
      specialtyName: '',
      hasUnsavedChanges: false,
      isNew: false,
      specialties: [],
      drLoaded: {},
      staticVariables: [],
    }
  },
  async mounted() {
    await this.init()
  },

  methods: {
    async init() {
      this.isNew = !this.$route.query.id
      this.loading = true
      this.isDataReportProduct = isNaN(parseInt(this.$route.query.id))
      this.id = this.isDataReportProduct ? this.$route.query.id : parseInt(this.$route.query.id)
      if (this.$route.query.id) {
        this.drLoaded = await this.getQuery(this.id)
        this.query = this.drLoaded.query
        this.name = this.drLoaded.name
        this.specialtyName = this.drLoaded.specialtyName
        try {
          this.variables = JSON.stringify(this.drLoaded.variables, null, 2)
        } catch (e) {
          this.variables = '{}'
        }
      } else {
        this.name = ''
        this.id = 0
        this.variables = '{}'
        this.query = 'query {}'
        await this.getSpecialties()
      }
      this.hasUnsavedChanges = false
      this.loading = false
    },
    handleQueryChanges(e) {
      this.query = e
      this.hasUnsavedChanges = true
    },
    handleVariableChanges(e) {
      this.variables = e
      this.hasUnsavedChanges = true
      this.staticVariablesChanges()
    },
    async getQuery(id) {
      return await dataReportService.getById(id)
    },
    async getSpecialties() {
      this.specialties = await specialtyService.list()
    },
    async changeSpecialty() {
      const specialty = this.specialties.find(s => s.name === this.specialtyName)
      this.variables = `{\n${specialty.settings.map(s => `"${s.name}": "${s.defaultValue || ''}"`).join(',\n')}\n}`
    },
    async save() {
      let variables = {}
      if (!this.name) {
        $root.toast({ description: this.t.data_report_page.error.invalidName, type: 'error', timeout: 5000 })
        return
      }
      if (!this.specialtyName) {
        $root.toast({ description: this.t.data_report_page.error.noSpecialty, type: 'error', timeout: 5000 })
        return
      }
      try {
        variables = JSON.parse(this.variables)
      } catch (e) {
        $root.toast({ description: this.t.data_report_page.error.invalidVariables, type: 'error', timeout: 5000 })
        return
      }
      if (this.isNew) {
        try {
          await dataReportService.create({
            name: this.name,
            query: this.query,
            variables,
            specialtyName: this.specialtyName,
          })
          this.$router.push($root.appath + 'data-reports')
        } catch (e) {
          $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        } finally {
          this.hasUnsavedChanges = false
        }
      } else {
        try {
          await dataReportService.update(this.id, { name: this.name, query: this.query, variables })
          $root.toast({ description: $root.t.saved || 'Saved', type: 'success', timeout: 5000 })
        } catch (e) {
          $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        } finally {
          this.hasUnsavedChanges = false
        }
      }
    },
    openStudio() {
      return window.open(this.studioLink, '_blank')
    },
    staticVariablesChanges() {
      try {
        const variablesName = Object.keys(JSON.parse(this.variables))
        const specialtyVariableNames = []
        if (this.isNew) {
          this.specialties
            .find(s => s.name === this.specialtyName)
            ?.settings.forEach(s => {
              specialtyVariableNames.push(s.name)
            })
        } else {
          this.drLoaded.settingVariableParameters.forEach(s => {
            specialtyVariableNames.push(s.name)
          })
        }

        this.staticVariables = variablesName.filter(v => !specialtyVariableNames.includes(v))
      } catch (e) {}
    },
  },
  computed: {
    headerText() {
      return this.isNew
        ? `${this.t.data_report_page.header.new}`
        : `${this.t.data_report_page.header.edit} ${this.name}`
    },
    url() {
      return `${config.graphqlEndpoint}/dr/${this.id}/run${this.varUrl}`
    },
    varUrl() {
      if (!this.variables) return ''
      let variables
      try {
        variables = JSON.parse(this.variables)
      } catch (e) {
        return ''
      }

      return Object.entries(variables).reduce((a, [k, v]) => {
        return `${a}${a ? '&' : '?'}${k}=${encodeURI(v)}`
      }, '')
    },
    studioLink() {
      const q =
        this.query.length > 5000
          ? this.query
              .replace(/\s+/g, ' ')
              .replace(/\s?{\s?/g, '{')
              .replace(/\s?}\s?/g, '}')
              .replace(/\s?:\s?/g, ':')
          : this.query
      const endpoint = config.graphqlEndpoint
      return `https://studio.apollographql.com/sandbox?endpoint=${encodeURI(
        new URL(endpoint, location.origin).href,
      )}&document=${encodeURI(q)}&variables=${encodeURI(this.variables)}&headers=${encodeURI(
        JSON.stringify({
          Authorization: `Bearer ${$root?.profile?.idToken}`,
          'x-valuation-style': $root.valuationStyle,
        }),
      )}`
    },
    staticVariablesText() {
      return this.staticVariables.length > 1
        ? this.t.data_report_page.staticVariables.replace('<XX>', this.staticVariables.length)
        : this.t.data_report_page.staticVariable
    },
  },
}
</script>

<style scoped>
.mr2 {
  margin-right: 8px;
}
.ml2 {
  margin-left: 8px;
}
.mt4 {
  margin-top: 16px;
}
.mb2 {
  margin-bottom: 8px;
}
.data-report button svg {
  height: 16px;
  width: 16px;
}
.data-report .buttons button {
  margin-right: 5px;
}

.static-tooltip {
  font: var(--p1);
}
.static-tooltip-text {
  font: var(--p2);
}

label {
  font: var(--h3);
}
</style>
