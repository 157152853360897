<template>
  <builder-chart class="graph-table" v-bind="{ ...props, data: tableData, nxChartOptions }">
    <template #header="tableProps">
      <tr class="table-header">
        <th class="table-header-cell" v-for="col in tableProps.columns" :key="col">
          <div v-if="col !== tableProps.options.x" v-html="tableProps.formatLabel(col)"></div>
          <div v-else-if="options.tableName" v-html="translate(options.tableName)"></div>
        </th>
      </tr>
    </template>
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<style scoped></style>

<script setup lang="ts">
import { computed } from 'vue'
import { table as api } from '../composables/builderOptions'
import { bar as story } from './stories'
import { BuilderProps } from '../composables/builderComponent'
import useTranslations from '../composables/translations'
const viz = 'table'

const props = withDefaults(defineProps<BuilderProps>(), {})
const { translate } = useTranslations(props)
const tableData = computed(() => {
  const translatedData = props.data
  if (props.options.showTotal) {
    const total = translatedData.reduce((acc, row) => {
      Object.keys(row).forEach(key => {
        if (typeof row[key] === 'number') {
          acc[key] = (acc[key] || 0) + row[key]
        }
      })
      return acc
    }, {})
    return [...translatedData, { key: translate.value('total'), ...total }]
  }
  return translatedData
})
const nxChartOptions = { viz, lib: 'nx' }
</script>

<script lang="ts">
export default {
  api: {
    ...api,
    tableName: {
      label: 'Table Name',
      default: () => null,
      type: 'translationInput',
    },
    showTotal: {
      label: 'Show Total',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
  styles: {
    '.graph-table .table-header': `font-weight: bold;
.table-header-cell:first-of-type {
  text-align: left;
}`,
    '.graph-table .table-row': `.table-cell:first-of-type {
  text-align: left;
}`,
    '.graph-table .table-cell': `padding: 4px 8px;
text-align: center;`,
    '.graph-table .table-header-cell': `padding: 4px 8px;
text-align: center;`,
    '.graph-table .all-table': {
      name: 'Table',
      css: `
.table-row:nth-child(even) {
  background-color: color-mix(in srgb, var(--primary) 10%, transparent);
}`,
    },
  },
  story,
}
</script>
