<style scoped>
.pdf-page:nth-child(2):not(.en_hk) .brick:not(.header):not(.footer):not(.purchase):not(.sale):not(.reinforcement):not(.cutback), .pdf-page.en_hk:nth-child(3) .brick:not(.header):not(.footer):not(.purchase):not(.sale):not(.reinforcement):not(.cutback) {
  margin-top: 15px;
}
.block .main-lines .block:first-child {
  padding: 0;
  margin: 3px 8px 0 0 !important;
}
.block .main-lines .block:last-child {
  padding: 0;
  margin: 3px 0 0 8px !important;
}

.logo_agec img { height: 40px; position: absolute; bottom: 45px; right: 0;}
</style>
<script>
import useAuth from '@100-m/hauru/src/features/auth.js'
import pdf from '../../mixins/pdf.js'
import { getDataReport } from '../../composables/useDataReport'

export const additions = {
  theme: '.pdf-page.grid\n  brick.no-title(data="block.header")\n  brick.expand\n  brick.no-title(data="block.footer")\n',
}
export default {
  setup() {
    const { profile } = useAuth()
    return { profile }
  },
  mixins: [pdf],
  data() {
    return {
      loaded: false,
      isin_share: this.$route.fullPath.split('/')[2].split('-')[1],
      domain: this.$route.query.domain,
      asof: this.$route.query.asof,
      lang: this.$route.query.lang || $root.lang,
      // period: this.$route.query.period || 'monthly',
      data: {},
    }
  },
  async created() {
    this.data = await getDataReport()
    this.loaded = true
  },
}
</script>

<template lang="pug">
template(v-if="loaded")
  //PAGE-disclaimer-en_hk
  page1-disclaimer(:data="data")
  //PAGE-1
  page1(:data="data" :period="data.generated_period")
  //PAGE-2
  .pdf-page.grid(:class="{[data.share.characteristics.theme]: true, en_hk: $root.lang === 'en_hk'}")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    .subtitle.feeder.row.keep_margin(v-if="data.share.master_fund") {{ t.disclaimer_feeder + data.share.master_fund.name }}
    .row
      .column
        brick.no-bold(v-bind="data.graphs[0]" v-if="data.graphs[0]")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        brick.no-bold(v-bind="data.graphs[1]" v-if="data.graphs[1]")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        brick.no-bold(v-bind="data.graphs[2]" v-if="data.graphs[2]")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        brick.no-bold(v-bind="data.graphs[3]" v-if="data.graphs[3]")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        // Ajouter pour Mounir: il est prevenu qui si overflow sur cette colonne car 4 graph => ce n'est pas un bug (09/11/21)
        brick.no-bold(v-bind="data.graphs[4]" v-if="data.graphs[4]")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        brick.no-bold.center-value.performance_indicators.expand(
          :title="data.share.characteristics.table_second_page_bottom_left"
          :data="data.tables.table_second_page_bottom_left"
          :subtitle="data.share.characteristics.table_second_page_bottom_left + '_subtitle'"
          v-if="data.share.characteristics.table_second_page_bottom_left"
        )
          .row.risk_disclaimer(v-if="data.share.characteristics.benchmark_esg") {{ $root.t['univers_definition'] + ': ' + $root.t[data.share.characteristics.benchmark_esg] }}
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        //Pour ajouter un tableau dans disclaimer benchmark esg:
        brick.no-bold.center-value.performance_indicators.expand(
          :title="data.share.characteristics.table_second_page_bottom_left_no_benchmark_esg_disclaimer"
          :data="data.tables.table_second_page_bottom_left_no_benchmark_esg_disclaimer"
          :subtitle="data.share.characteristics.table_second_page_bottom_left_no_benchmark_esg_disclaimer + '_subtitle'"
          v-if="data.share.characteristics.table_second_page_bottom_left_no_benchmark_esg_disclaimer"
        )
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      .column
        //histo alloc
        brick.histo-alloc(
          :title="'allocation_historic_'+ data.share.characteristics.display_histo_alloc.replaceAll('.','_')"
          :subtitle="t['allocation_historic_'+ data.share.characteristics.display_histo_alloc.replaceAll('.','_') + '_subtitle']"
          type="plot-base"
          :options="histo_alloc_options"
          :data="histo_alloc_data"
          v-if="data.share.characteristics.display_histo_alloc"
        )
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        //main_issuers
        brick.no-bold.main_table(
          :title="data.share.characteristics.main_table_type"
          :data="issuers_top10_formated"
          :options="{ limit: 11, format:'.2%' }"
          subtitle="subtitle_main_lines"
          v-if="data.share.characteristics.main_table_type === 'main_issuers_table'"
        )
          pdf-table-details(
            :label1="t.nb_issuers"
            :value1="data.share.allocation_analytics.issuers_count"
            :label2="t.weight_first_10_issuers"
            :value2="format('.2%')(issuers_top10_formated.reduce((acc,a)=>acc+a.weight, 0))"
          )
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        //main_lines
        brick.no-bold.main_table(
          :title="[data.share.characteristics.main_table_type.split(',')].flat().join('_')"
          subtitle="subtitle_main_lines"
          v-if="[data.share.characteristics.main_table_type].flat()[0].includes('main_lines_table')"
        )
          .row.main-lines(v-if="[data.share.characteristics.main_table_type.split(',')].flat()[0].includes('main_lines_table')")
            brick.no-bold.main_table(
              v-for="table, index in [data.share.characteristics.main_table_type.split(',')].flat()"
              :data="main_lines_formated[table]"
              :options="{ limit: 11, format:'.2%' }"
            )
        pdf-table-details(
          :label1="t.line_number"
          :value1="data.share.allocation_analytics.lines_count"
          :label2="t.main_10_lines"
          :value2="format('.2%')(data.share.allocation_analytics.lines_top10)"
          v-if="[data.share.characteristics.main_table_type].flat()[0].includes('main_lines_table')"
        )
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        // Delta & sensitaux
        .row.delta-sensi(v-if="displayDelta || displaySensi")
          brick.nomargin(
            :title="(t.delta_action + ' (' + format('.2%')(data.share.analytics.last_delta_action) + ')').replace(',','.')"
            type="line"
            :data="historical_delta_action.data"
            :options="historical_delta_action.options"
            v-if="displayDelta"
            @rebind="historical_delta_action.options.format_xAxis && $event.xAxis.axisConfigurations([[parseAxis(historical_delta_action.options.format_xAxis )]])"
          )
            div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
          brick.nomargin(
            :title="(t.sensitaux + ' (' + format('.2f')(data.share.analytics.last_sensitaux) + ')').replace(',','.')"
            type="line"
            :data="historical_sensitaux.data"
            :options="historical_sensitaux.options"
            v-if="displaySensi"
            @rebind="historical_sensitaux.options.format_xAxis && $event.xAxis.axisConfigurations([[parseAxis(historical_sensitaux.options.format_xAxis )]])"
          )
            div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        // Movements
        brick(
          title="main_movements"
          :subtitle="t[data.share.characteristics.movements_filter] || t.subtitle_type_movement"
          v-if="data.share.characteristics.display_movements"
        )
        .row.movements(v-if="data.share.characteristics.display_movements")
          brick.no-bold(:data="format_transactions('purchase', data.share.transactions.purchase)" :options="{ limit: 5, format: '.2%' }")
          brick.no-bold(:data="format_transactions('sale', data.share.transactions.sale)" :options="{ limit: 5, format: '.2%' }")
        .row.movements(v-if="data.share.characteristics.display_movements")
          brick.no-bold(:data="format_transactions('reinforcement', data.share.transactions.reinforcement)" :options="{ limit: 5, format: '.2%' }")
          brick.no-bold(:data="format_transactions('cutback', data.share.transactions.cutback)" :options="{ limit: 5, format: '.2%' }")
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    pdf-footer(:share="data.share.characteristics")
  //products risk & glossary BE
  .pdf-page.definition_page.grid(
    v-if="(data.share.characteristics.product_risk_list_be || []).length > 0 && this.lang.includes('be')"
    :class="data.share.characteristics.theme"
  )
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    .column
      brick.risk_disclaimer(title="product_risk")
        div(v-html="t[risk] || risk" v-for="risk in data.share.characteristics.product_risk_list_be.split(',')")
      brick.risk_disclaimer(title="glossary")
        div(v-html="t[def] || def" v-for="def in data.share.allocation_analytics.glossary")
    pdf-footer(:share="data.share.characteristics")
  //external pdf
  .pdf-page.grid(
    v-for="document in data.share.external_assets"
    :class="data.share.characteristics.theme"
    v-if="data.generated_period === 'monthly'"
  )
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    img.pdf-image(:src="download_asset(document)" class='pdf-image')
    pdf-footer(:share="data.share.characteristics")
  //ESG
  .pdf-page.grid.esg-page(v-if="data.share.characteristics.page_esg === 'generique'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-esg-generique(:data="data")
    pdf-footer(:share="data.share.characteristics")
  .pdf-page.grid.carbon-page(v-if="data.share.characteristics.page_esg === 'carbone'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-esg-carbon-1(:data="data")
    pdf-footer(:share="data.share.characteristics")
  .pdf-page.grid.carbon-page(v-if="data.share.characteristics.page_esg === 'carbone'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-esg-carbon-2(:data="data")
    pdf-footer(:share="data.share.characteristics")
  .pdf-page.grid.diversifie-page(v-if="data.share.characteristics.page_esg === 'diversifie'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-esg-diversifie(:data="data")
    pdf-footer(:share="data.share.characteristics")
  .pdf-page.grid.mai-page(v-if="data.share.characteristics.page_esg === 'mai'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-esg-mai-1(:data="data")
    pdf-footer(:share="data.share.characteristics")
  .pdf-page.grid.mai-page(v-if="data.share.characteristics.page_esg === 'mai'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-esg-mai-2(:data="data")
    pdf-footer(:share="data.share.characteristics")
  .pdf-page.grid.fonds_de_fonds-page(v-if="data.share.characteristics.page_esg === 'fonds_de_fonds'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-esg-fonds_de_fonds(:data="data")
    pdf-footer(:share="data.share.characteristics")
  .pdf-page.grid.diversifie_dynamique-page(v-if="data.share.characteristics.page_esg === 'diversifie_dynamique'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-esg-diversifie_dynamique(:data="data")
    pdf-footer(:share="data.share.characteristics")
  .pdf-page.grid.cigg-page(v-if="data.share.characteristics.page_esg === 'cigg'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-esg-cigg-1(:data="data")
    pdf-footer(:share="data.share.characteristics")
  .pdf-page.grid.cigg-page(v-if="data.share.characteristics.page_esg === 'cigg'" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    .row(v-if="data.share.characteristics.esg_impact_table")
      impact-esg-table(:characteristicsData="data.share.characteristics" :mapping="window.mapping")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    pdf-esg-cigg-2(:data="data")
    pdf-footer(:share="data.share.characteristics")
  //management comment
  .pdf-page.grid.esg-page(v-if="data.share.comment !==null" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    brick(title="management_comment_title")
      .management_comment(v-html="data.share.comment.value.replaceAll('\\n', '</br>')")
    pdf-footer(:share="data.share.characteristics")
  //standard disclaimer
  .pdf-page.definition_page.grid(v-if="(data.share.characteristics.disclaimer_list || []).length > 0 || (data.share.characteristics.definition_list || []).length > 0" :class="data.share.characteristics.theme")
    pdf-header(:share="data.share" :period="data.generated_period" :domain="perf_domain")
    pdf-last-page(:data="data")
    .logo_agec
      img(:src="'logo_agec.png'")
    pdf-footer(:share="data.share.characteristics")
</template>
