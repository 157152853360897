<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center">
      <div class="flex-1">Footnotes</div>
      <button class="hover:underline" @click="addFootnote">+ Add footnote</button>
    </div>
    <datalist id="datapointsTranslation">
      <option></option>
      <option v-for="(v, k) in context.translations" :value="`translation.${k}`" :key="k">
        {{ truncString(v, 50) }}
      </option>
    </datalist>
    <!-- <div>{{ footnotes }}</div> -->
    <!-- <div>--{{ footerPath }}</div> -->
    <div class="flex flex-col" v-if="hasGlobalFootnotes">
      <div>Cannot have block footnotes when global footnotes are defined</div>
      <button
        class="text-left hover:underline"
        @click="emit('active', footnoteComponentPath)"
        v-if="footnoteComponentPath"
      >
        Go to footnote component
      </button>
    </div>
    <div class="flex flex-col" v-else-if="hasPageFootnotes">
      <div>Cannot have block footnotes when page footnotes are defined</div>
      <button class="text-left hover:underline" @click="emit('active', footerPath)" v-if="footerPath">
        Go to footer component
      </button>
    </div>
    <template v-else-if="footnotes && footnotes.length">
      <div class="flex flex-row items-center gap-2">
        <div class="h-6 w-6"></div>
        <div class="flex-1">Anchor</div>
        <div class="ml-1 flex-1">Value</div>
        <div class="h-1 w-7"></div>
      </div>
      <nx-dropdown-select-list
        :list="footnotes"
        :toggle="() => {}"
        :isToggleable="false"
        @onChange="onFootnoteChange"
        class="footnote-list"
        group="sort"
      >
        <template #item="{ element }">
          <div class="my-1 flex flex-row items-center gap-2">
            <nx-button @click="deleteFootnote(element)" class="!h-6 !w-6 !p-1 hover:bg-neutral-600">
              <span class="i-[mdi/close] text-white"></span>
            </nx-button>
            <input
              type="text"
              class="rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              list="datapointsTranslation"
              v-model="element.key"
            />
            <input
              type="text"
              class="rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
              list="datapointsTranslation"
              v-model="element.value"
            />
          </div>
        </template>
      </nx-dropdown-select-list>
    </template>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed, nextTick } from 'vue'
import { Footnote, TemplateLayout } from '../../builder'
import { traverseObject } from '../../lib/utils'
import useLayout from '../../composables/layout'

const props = defineProps(['path', 'store', 'component', 'context'])
const template = defineModel<TemplateLayout>()
const emit = defineEmits(['active'])
const { getNode, updateNode, updateLayout } = useLayout(props.store, template)

const footnotes = ref<Footnote[]>([])
watch(
  () => props.path,
  path => {
    const node = getNode(path)
    footnotes.value = node?.footnotes || []
  },
  { immediate: true },
)

const hasGlobalFootnotes = computed(() => {
  // If no path, we're looking at the global footnotes
  if (!props.path.length) return false
  return template.value.footnotes?.length
})

const footnoteComponentPath = computed(() => {
  if (!hasGlobalFootnotes.value) return
  let footnoteComponentPath
  // debugger
  traverseObject(template.value, (v, path) => {
    if (path[path.length - 1] === 'component' && v === 'footnotes') {
      footnoteComponentPath = path
        .slice(0, -1)
        .filter(d => d !== 'nodes')
        .map(d => parseInt(d))
    }
    //   const cssId = 'block-' + path.slice(0, -1).join('-')
    //   blockStyleOverrides[cssId] = v
    // }
    // console.log(path, path[path.length - 1])
  })
  return footnoteComponentPath
})
const hasPageFootnotes = computed(() => {
  // If component is footer, we're looking at the page footnotes
  if (props.component === 'footer') return false
  const page = template.value.nodes[props.path[0]]
  return page?.footnotes?.length
})
const footerPath = computed(() => {
  if (!hasPageFootnotes.value) return
  const page = template.value.nodes[props.path[0]]
  return [props.path[0], page.nodes?.findIndex(d => d.component === 'footer')]
  // return props.path.slice(0, -1)
})
async function updateFootnotes() {
  if (!footnotes.value) return
  if (props.path.length) {
    const node = getNode(props.path)
    updateNode(props.path, { ...node, footnotes: footnotes.value })
  } else {
    template.value.footnotes = footnotes.value
  }
  updateLayout(props.path)
}
function addFootnote() {
  if (!footnotes.value) {
    footnotes.value = [{ key: '', value: '' }]
  } else {
    footnotes.value.push({ key: '', value: '' })
  }
  updateFootnotes()
}
function deleteFootnote(footnote: Footnote) {
  if (!footnotes.value) return
  footnotes.value = footnotes.value.filter(f => f !== footnote)
  updateFootnotes()
}
function onFootnoteChange(ev: Event) {
  updateFootnotes()
}
function truncString(str: string, length: number) {
  return str.length > length ? str.slice(0, length) + '...' : str
}
</script>
<style scoped>
.footnote-list :deep(.w-2.shrink-0) {
  display: none;
}
.footnote-list :deep(> div) {
  padding-left: 0;
}
</style>
