<template>
  <fieldset class="dr-variable-input" v-if="['select', 'input', 'date'].includes(inputType)">
    <slot name="label" :variableDefinition="variableDefinition">
      <label>{{ variableDefinition.name }}</label>
    </slot>
    <input v-if="inputType === 'input'" :value="value" @input="updateValue" />
    <template v-else-if="missingDependencies?.length">
      <slot name="missing-dependencies" :missingDependencies="missingDependencies">
        <div class="missing-dependencies">Please select a value for: {{ missingDependencies.join(', ') }}</div>
      </slot>
    </template>
    <div v-else-if="!inputData">Loading</div>
    <select v-else-if="inputType === 'select' && inputData" :value="value" @input="updateValue">
      <option></option>
      <option v-for="opt in inputData" :value="opt.value" :key="opt.name">{{ opt.name }}</option>
    </select>
  </fieldset>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { VariableDefinition, VariableOption, variableTypes } from './lib'
interface IProps {
  variableDefinition: VariableDefinition
  value?: string | number
  inputData?: VariableOption[]
  missingDependencies?: string[]
  // starting
}

const props = defineProps<IProps>()
const emit = defineEmits(['update:value'])
function updateValue(event: Event) {
  emit('update:value', (event.target as HTMLSelectElement).value)
}
const inputType = computed(() => {
  return variableTypes[props.variableDefinition?.variableType]?.inputType
})
</script>
