<template>
  <div class="lfr-footer !border-0 !border-t-2 border-primary flex h-fit flex-col justify-between bg-white">
    <div class="flex h-fit w-full flex-row items-center justify-between bg-transparent px-6 py-2">
      <div class="items start flex flex-col justify-between space-y-1">
        <div class="flex text-[7px] leading-[11.2px]" v-html="disclaimer"></div>
      </div>
      <img class="h-12 p-1 ml-2 mr-2" :src="footerImg" alt="Logo LFR" />
      <div class="text-primary text-xs font-medium">{{ pagenumber }}/{{ pagelength }}</div>
    </div>
  </div>
</template>

<style>
.lfr-footer {
  margin-top: auto !important;
  margin-bottom: calc(var(--page-margin) * -1);
}
</style>

<script setup>
import { marked } from 'marked'
import insane from 'insane'
import { getCurrentInstance, onMounted, ref, computed } from 'vue'
const { data, options } = defineProps(['data', 'options'])
const pagenumber = ref(0)
const pagelength = ref(0)
onMounted(() => {
  const el = getCurrentInstance().vnode.el
  const pages = Array.from(document.querySelectorAll('.pdf-page'))
  pagenumber.value = pages.findIndex(page => page === el.parentNode) + 1
  pagelength.value = pages.length
})
const disclaimer = computed(() => {
  const disc = data['nxpack-fund-ref'].disclaimer_footer
  if (typeof disc !== 'string') return disc
  return insane(marked(disc))
})
const footerImg = computed(() => options.layout.theme?.assets?.find(el => el.name === 'logo-footer.png')?.src || '/logo-footer.png')
</script>
