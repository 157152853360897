<template lang="pug">
.stars.morningstar
  div {{t.MS_overall_rating || "MS_overall_rating"}}
    span.normal :
  .star(v-for="i in starToDisplay")
    <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path d="M6.97.843l1.466 4.418 4.655.028-3.75 2.76 1.412 4.435L6.97 9.771l-3.782 2.713 1.41-4.436L.852 5.29l4.654-.028z" fill="#2a4371" fill-rule="evenodd"/></svg>
</template>

<style scoped>
.stars { display: flex;align-items: center; transform: translateY(-8px); font-weight: bold; }
.stars > :first-child { min-width: 57px; margin-left: 2px; }
.stars .star { display: flex;align-items: center;justify-content: center;width: 12px;height: 12px;margin: 1px;padding-right: 1px;line-height: 1;color: var(--text);background: var(--highlight);border-radius: 50%;font-size: 8px;font-weight: 600; }
.morningstar .star { width: 14px;height: 14px;margin: 0;padding: 0;background: none; }
.morningstar .star svg { width: 12px;height: 12px; }
.normal { font-weight: normal; margin-left: 2px; }
</style>

<script setup lang="ts">
const props = defineProps({
  starNumber: Number,
})
const starToDisplay = Math.min(5, Math.max(1, Math.round(props.starNumber)))

</script>