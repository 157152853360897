import { processTable, titleize_normal, contextualizationTable } from "./useProcessTable"
import { processGraph } from "./useProcessGraph"

export async function getDataReport() {
  const last_date = $root.$route.query.domain.split('|').last()
  const first_date = $root.$route.query.domain.split('|').first()
  const variables = {
    isinShare: $root.$route.fullPath.split('/')[2].split('-')[1],
    domain: $root.query.evolution ? $root.$route.query.domain : first_date + '|' + last_date,
    asof: $root.query.asof,
    lang: $root.$route.query.lang || $root.lang,
    endDate: last_date,
  }
  variables.period = $root.$route.query.period || get_period(variables.domain) || 'monthly'

  const resp = await axios({
    url: '/dr',
    method: 'post',
    data: {
      variables,
      query: `query DataReportQuery(
  $isinShare: String!
  $domain: Domain!
  $lang: Lang!
  $period: ReportPeriod
  $asof: Asof
  $endDate: UTCDate
) {
  tables {
    name
    table
  }
  share(isin_share: $isinShare, asof: $asof) {
    characteristics
    share_letter
    labels
    fund_id
    fund_name
    share_id
    performanceDates
    allocationDates
    share_currency
    fund_type
    benchmark_multiple
    benchmark {
      id
      label
    }
    pdf_benchmarks {
      id
      label
      asset {
        id
        name
        isin
        asof
        dimensions
      }
    }
    master_fund {
      id
      name
      isin
      asof
    }
    analytics {
      alpha_1y: p_alpha(date: $endDate period: "1y")
      alpha_3y: p_alpha(date: $endDate period: "3y")
      alpha_5y: p_alpha(date: $endDate period: "5y")
      beta_1y: p_beta(date: $endDate period: "1y")
      beta_3y: p_beta(date: $endDate period: "3y")
      beta_5y: p_beta(date: $endDate period: "5y")
      information_ratio_1y: p_information_ratio(date: $endDate period: "1y" metric: "benchmark")
      information_ratio_3y: p_information_ratio(date: $endDate period: "3y" metric: "benchmark")
      information_ratio_5y: p_information_ratio(date: $endDate period: "5y" metric: "benchmark")
      information_ratio_10y: p_information_ratio(date: $endDate period: "10y" metric: "benchmark")
      information_ratio_1y_benchmark2: p_information_ratio(date: $endDate period: "1y" metric: "benchmark2")
      information_ratio_3y_benchmark2: p_information_ratio(date: $endDate period: "3y" metric: "benchmark2")
      information_ratio_5y_benchmark2: p_information_ratio(date: $endDate period: "5y" metric: "benchmark2")
      information_ratio_10y_benchmark2: p_information_ratio(date: $endDate period: "10y" metric: "benchmark2")
      information_ratio_1y_benchmark3: p_information_ratio(date: $endDate period: "1y" metric: "benchmark3")
      information_ratio_3y_benchmark3: p_information_ratio(date: $endDate period: "3y" metric: "benchmark3")
      information_ratio_5y_benchmark3: p_information_ratio(date: $endDate period: "5y" metric: "benchmark3")
      information_ratio_10y_benchmark3: p_information_ratio(date: $endDate period: "10y" metric: "benchmark3")
      sharpe_ratio_1y: p_sharpe_ratio(date: $endDate period: "1y")
      sharpe_ratio_3y: p_sharpe_ratio(date: $endDate period: "3y")
      sharpe_ratio_5y: p_sharpe_ratio(date: $endDate period: "5y")
      sharpe_ratio_10y: p_sharpe_ratio(date: $endDate period: "10y")
      sharpe_ratio_1y_day_4: p_sharpe_ratio(date: $endDate period: "1y" day: 4)
      sharpe_ratio_3y_day_4: p_sharpe_ratio(date: $endDate period: "3y" day: 4)
      sharpe_ratio_5y_day_4: p_sharpe_ratio(date: $endDate period: "5y" day: 4)
      sharpe_ratio_10y_day_4: p_sharpe_ratio(date: $endDate period: "10y" day: 4)
      tracking_error_1y: p_tracking_error(date: $endDate period: "1y")
      tracking_error_3y: p_tracking_error(date: $endDate period: "3y")
      tracking_error_5y: p_tracking_error(date: $endDate period: "5y")
      tracking_error_10y: p_tracking_error(date: $endDate period: "10y")
      tracking_error_1y_day_4: p_tracking_error(date: $endDate period: "1y" day: 4)
      tracking_error_3y_day_4: p_tracking_error(date: $endDate period: "3y" day: 4)
      tracking_error_5y_day_4: p_tracking_error(date: $endDate period: "5y" day: 4)
      tracking_error_10y_day_4: p_tracking_error(date: $endDate period: "10y" day: 4)
      var_X_Y_days_1y_fund_95_7: p_var_X_Y_days(date: $endDate period: "1y" X: 95 Y: 7)
      var_X_Y_days_3y_fund_95_7: p_var_X_Y_days(date: $endDate period: "3y" X: 95 Y: 7)
      var_X_Y_days_5y_fund_95_7: p_var_X_Y_days(date: $endDate period: "5y" X: 95 Y: 7)
      var_X_Y_days_1y_fund_99_10: p_var_X_Y_days(date: $endDate period: "1y" X: 99 Y: 10)
      var_X_Y_days_3y_fund_99_10: p_var_X_Y_days(date: $endDate period: "3y" X: 99 Y: 10)
      var_X_Y_days_5y_fund_99_10: p_var_X_Y_days(date: $endDate period: "5y" X: 99 Y: 10)
      volatility_3m_fund: p_volatility(date: $endDate period: "3m" metric: "fund")
      volatility_1y_fund: p_volatility(date: $endDate period: "1y" metric: "fund")
      volatility_3y_fund: p_volatility(date: $endDate period: "3y" metric: "fund")
      volatility_5y_fund: p_volatility(date: $endDate period: "5y" metric: "fund")
      volatility_10y_fund: p_volatility(date: $endDate period: "10y" metric: "fund")
      volatility_1y_fund_day_4: p_volatility(date: $endDate period: "1y" metric: "fund" day: 4)
      volatility_3y_fund_day_4: p_volatility(date: $endDate period: "3y" metric: "fund" day: 4)
      volatility_5y_fund_day_4: p_volatility(date: $endDate period: "5y" metric: "fund" day: 4)
      volatility_10y_fund_day_4: p_volatility(date: $endDate period: "10y" metric: "fund" day: 4)
      volatility_1y_benchmark: p_volatility(date: $endDate period: "1y" metric: "benchmark")
      volatility_3y_benchmark: p_volatility(date: $endDate period: "3y" metric: "benchmark")
      volatility_5y_benchmark: p_volatility(date: $endDate period: "5y" metric: "benchmark")
      volatility_10y_benchmark: p_volatility(date: $endDate period: "10y" metric: "benchmark")
      volatility_1y_benchmark2: p_volatility(date: $endDate period: "1y" metric: "benchmark2")
      volatility_3y_benchmark2: p_volatility(date: $endDate period: "3y" metric: "benchmark2")
      volatility_5y_benchmark2: p_volatility(date: $endDate period: "5y" metric: "benchmark2")
      volatility_10y_benchmark2: p_volatility(date: $endDate period: "10y" metric: "benchmark2")
      volatility_1y_benchmark3: p_volatility(date: $endDate period: "1y" metric: "benchmark3")
      volatility_3y_benchmark3: p_volatility(date: $endDate period: "3y" metric: "benchmark3")
      volatility_5y_benchmark3: p_volatility(date: $endDate period: "5y" metric: "benchmark3")
      volatility_10y_benchmark3: p_volatility(date: $endDate period: "10y" metric: "benchmark3")
      maxgain_details_5y: p_maxgain_details(date: $endDate period: "5y") { maxgain begin begin_nav end end_nav recovery duration }
      drawdown_details_5y: p_drawdown_details(date: $endDate period: "5y") { drawdown begin begin_nav end end_nav recovery duration }
      maxgain_details_inception: p_maxgain_details(date: $endDate period: "inception") { maxgain begin begin_nav end end_nav recovery duration }
      drawdown_details_inception: p_drawdown_details(date: $endDate period: "inception") { drawdown begin begin_nav end end_nav recovery duration }
      last_delta_action(domain: $domain)
      last_sensitaux(domain: $domain)
      historical {
        date
        fund
        benchmark
        benchmark2
        benchmark3
      }
      domain_historical(domain: $domain) {
        date
      }
      cumulated_net(domain: $domain, period: $period) {
        period
        fund
        benchmark
        benchmark2
        benchmark3
      }
      annualised_net(domain: $domain, period: $period, lang: $lang) {
        period
        fund
        benchmark
        benchmark2
        benchmark3
      }
      net_asset_value(domain: $domain)
      aum_fund_share_currency(domain: $domain)
      monthly_performances(domain: $domain) {
        date
        month
        fund
        benchmark
        benchmark2
        benchmark3
      }
      yearly_performances(domain: $domain) {
        date
        year
        fund
        benchmark
        benchmark2
        benchmark3
      }
      weekly_historical(domain: $domain) {
        date
        sensitaux
        delta_action
        benchmark_sensitaux
        benchmark2_sensitaux
        benchmark3_sensitaux
        benchmark_delta_action
        benchmark2_delta_action
        benchmark3_delta_action
      }
    }
    external_assets(domain: $domain)
    comment(domain: $domain, lang: $lang, asof: $asof, period: $period) {
      fund_id
      period
      date
      value
      lang
    }
    transactions(domain: $domain) {
      sale {
        id
        var
        type
        name
      }
      cutback {
        id
        var
        type
        name
      }
      purchase {
        id
        var
        type
        name
      }
      reinforcement {
        id
        var
        type
        name
      }
    }
    transactions_history(domain: $domain) {
      name
      date
      transaction_type
      amount
    }
    allocation_history(domain: $domain) {
      metric
      dimension_value
      dimension
      entries {
        date
        weight_fund
        exposure_fund
      }
    }
    allocation_analytics(domain: $domain) {
      lines_count
      lines_top10
      glossary
      graph {
        metric
        dimension
        group
        rebased
        data
      }
      lines {
        type
        metric
        count
        top10_weight
        header
        allocationsDesc {
          date
          fund_id
          name
          asset_class
          currency
          quantity
          price
          fixing
          valuation
          id
          isin
          asof
          date_d_echeance
          date_d_echeance_with_call
          weight_fund
          exposure_fund
          contrib_sensi_fund
          contrib_ytm_fund
          contrib_deltaaction_fund
          contrib_spreadobl_fund
          weight_benchmark
          exposure_benchmark
          contrib_sensi_benchmark
          contrib_ytm_benchmark
          contrib_deltaaction_benchmark
          contrib_spreadobl_benchmark
          weight_diff
          weight_active_share
          axis
        }
      }
      issuers {
        date
        weight_fund
      }
      issuers_count
      assets_count
      issuers_top10 {
        date
        fund_id
        name
        asset_class
        currency
        quantity
        price
        fixing
        valuation
        id
        isin
        asof
        date_d_echeance
        date_d_echeance_with_call
        weight_fund
        exposure_fund
        contrib_sensi_fund
        contrib_ytm_fund
        contrib_deltaaction_fund
        contrib_spreadobl_fund
        weight_benchmark
        exposure_benchmark
        contrib_sensi_benchmark
        contrib_ytm_benchmark
        contrib_deltaaction_benchmark
        contrib_spreadobl_benchmark
        weight_diff
        weight_active_share
        axis
      }
      # ESG
      esg_distribution { category fund univers }
      esg_asset_classes
      main_lines_table_esg { asc(limit: 10) { name weight_fund axis } desc(limit: 10) { name weight_fund axis } }
      main_lines_table_esg_cropped { asc(limit: 10) { name weight_fund axis } desc(limit: 10) { name weight_fund axis } }
      main_lines_table_carbon { asc(limit: 10) { name weight_fund axis } desc(limit: 10) { name weight_fund axis } }      
      main_lines_table_esg_issuers { asc(limit: 10) { issuer weight_fund axis } desc(limit: 10) { issuer weight_fund axis } }
      main_lines_table_esg_issuers_cropped { asc(limit: 10) { issuer weight_fund axis } desc(limit: 10) { issuer weight_fund axis } }
      main_lines_table_carbon_issuers { asc(limit: 10) { issuer weight_fund axis } desc(limit: 10) { issuer weight_fund axis } }
      main_lines_table_carbon_govies { asc(limit: 10) { issuer weight_fund axis } desc(limit: 10) { issuer weight_fund axis } }
      main_lines_table_esg_credit { asc(limit: 10) { issuer weight_fund axis } desc(limit: 10) { issuer weight_fund axis } }
      main_lines_table_esg_govies { asc(limit: 10) { issuer weight_fund axis } desc(limit: 10) { issuer weight_fund axis } }
      main_lines_table_esg_action { allocations { name weight_fund axis } }
      theme_breakdown { category fund univers }
      theme_credit { category fund univers }
      theme_govies { category fund univers }
      theme_actions { category fund univers }
      carbon_distribution { category fund univers }
      carbon_footprint_by_sector { category fund footprint footprint_diff }
      high_carbon
      low_carbon
      main_green_bonds { emetteur fund score_esg score_carbon_impact }
      main_carbon_provider { name weight_fund axis }
      sfdr_breackdown { category fund univers }
      impact_bonds_breackdown { category fund univers }
      callable_rate
      net_currency_exposure
      gross_rate_sensitivity
      cds_exposition
      odd_breakdown
      esg_impact_positif
      strat_mso_detailed_new_table
      greenfin_repartition
    }
    esg {
      benchmark_esg { id name isin asof dimensions }
      benchmark_poche_action { id name isin asof dimensions }
      benchmark_poche_obligations { id name isin asof dimensions }
      benchmark_poche_credit { id name isin asof dimensions }
      carbon_footprint
      carbon_footprint_difference
      carbon_intensity { fund label color key }
      carbon_intensity_difference
      scoring_climatique_credit_pocket { category fund univers }
      scoring_climatique_govies_pocket { category fund univers }
      scoring_climatique_action_pocket { category fund univers }
      carbon_intensity_credit_pocket
      carbon_intensity_govies_pocket
      carbon_intensity_action_pocket
      temperature_data { category fund univers }
    }
  }
  # translation(lang: $lang)
}`,
    },
  })
  if (resp.data.errors) console.error(resp.data.errors)
  const data = resp.data.data
  data.generated_period = variables.period
  data.share.fund_url = $root.params.userflow.split('-')[0]
  data.share.fund_or_mandat = data.share.characteristics.mandat_fictif === '' ? 'fund' : 'mandat'

  $root.t.no_sale = $root.t.no_sales
  $root.t.nan = $root.t.nan || $root.t.NA
  delete $root.t.NA

  const tables = Object.fromEntries(data.tables.map(v => Object.values(v)))
  // Contextualization Table: For characteristics and customer_informations:
  data.share.characteristics.customer_informations = contextualizationTable(tables, data.share.characteristics.customer_informations, 'customer_informations', variables.lang)
  data.share.characteristics.characteristics = contextualizationTable(tables, data.share.characteristics.characteristics, 'characteristics', variables.lang)

  const cleanCharacteristics = v => {
    if (v == null) return v
    if (/^(true|vrai)$/i.test(v)) return true
    if (/^(false|faux)$/i.test(v)) return false
    if (+v || v === '0') return +v
    if (typeof v === 'object') return v.map(cleanCharacteristics)
    return v
  }
  window.mapping = data.share.pdf_benchmarks.reduce((acc, v) => {acc[v.id] = { ...v.asset, ...v.asset?.dimensions?.map(cleanCharacteristics) }; return acc}, {})
  window.mapping[data.share.esg.benchmark_esg?.id] = { ...data.share.esg.benchmark_esg, ...data.share.esg.benchmark_esg?.dimensions?.map(cleanCharacteristics) }
  window.mapping[data.share.esg.benchmark_poche_action?.id] = { ...data.share.esg.benchmark_poche_action, ...data.share.esg.benchmark_poche_action?.dimensions?.map(cleanCharacteristics) }
  window.mapping[data.share.esg.benchmark_poche_obligations?.id] = { ...data.share.esg.benchmark_poche_obligations, ...data.share.esg.benchmark_poche_obligations?.dimensions?.map(cleanCharacteristics) }
  window.mapping[data.share.esg.benchmark_poche_credit?.id] = { ...data.share.esg.benchmark_poche_credit, ...data.share.esg.benchmark_poche_credit?.dimensions?.map(cleanCharacteristics) }
  data.share.characteristics = data.share.characteristics.map(cleanCharacteristics)
  data.share.allocation_analytics.lines = data.share.allocation_analytics.lines.map(v => {
    v.allocationsDesc = v.allocationsDesc.map(cleanCharacteristics)
    return v
  })
  data.graphs = data.share.allocation_analytics.graph.map((graph, position) => processGraph(graph, position, data))
  data.tables = data.share.characteristics.map(v => tables[v]).filter().map(table => processTable(table, data))
  if (window.errors) console.error(window.errors)

  // ESG
  const distribution = x => {
    const show_univers = data.share.characteristics.benchmark_esg && x?.map('univers')?.sum()
    return x?.reduce((acc, v) => {
      // const k = $root.t[v.category] || v.category
      //* for theme_govies and theme_credit horizontal bars colours
      const k = v.category
      if (show_univers) acc[k] = { fund: v.fund, univers: v.univers }
      else acc[k] = { fund: v.fund }
      return acc
    }, {})
  }
  const esgDistribution = x => {
    const show_univers = data.share.characteristics.benchmark_esg && x?.map('univers')?.sum()
    return x?.reduce((acc, v) => {
      const k = $root.t[v.category]
      if (show_univers) acc[k] = { fund: v.fund, univers: v.univers }
      else acc[k] = { fund: v.fund }
      return acc
    }, {
      '[0-1]': { fund: 0 },
      '[1-2]': { fund: 0 },
      '[2-3]': { fund: 0 },
      '[3-4]': { fund: 0 },
      '[4-5]': { fund: 0 },
      '[5-6]': { fund: 0 },
      '[6-7]': { fund: 0 },
      '[7-8]': { fund: 0 },
      '[8-9]': { fund: 0 },
      '[9-10]': { fund: 0 }
    })
  }
  const lines = (x, headers, titleize = true) => {
    const titleizeFn = titleize ? titleize_normal : x => x
    const asc = x.allocations
      .map(v => headers
        .map((header, i) => i === 0
          ? titleizeFn(v.name)
          : v[header.replace('weight', 'weight_fund')]
          || v.axis[header]).map(v => +v || v))
    const desc = [...asc].reverse()
    return { asc: [headers, ...asc], desc: [headers.map(v => v.replace('worst_scores', 'best_scores')), ...desc] }
  }
  const esgLines = (data, headers) => {
    const {asc, desc} = data
    const mapRows = (rows) => rows
      .map(v => headers.map((header, i) => {
        if (header === 'issuer') {
          return titleize_normal($root.t[v[header]])
        }
        if (header === 'name') {
          return titleize_normal(v[header])
        }
        if (header === 'weight') {
          return v.weight_fund
        }
        if (header === 'worst_scores') {
          return $root.t[v.issuer]
        }
        return v[header] || +v.axis[header];
      }))
    const headerDesc = [...headers]
    if(headerDesc[0] === 'worst_scores') headerDesc[0] = 'best_scores'
    return {
      asc: [headers, ...mapRows(asc)],
      desc: [headerDesc, ...mapRows(desc)],
    }
  }
  const scoringPocket = (p, type) => {
    const header = ['', type, 'univers']
    const data = p.map(({fund, univers, ...p}) => ({...p, fund: format('.2f')(fund), univers: format('.2f')(univers)}))
      .map(p => [p.category, p.fund, p.univers])
    return [header, ...data]
  }
  const temperature = (t) => {
    return t.reduce((acc, a) => {
      acc[a.category] = {
        fund: a.fund || undefined,
      }
      if(a.univers) {
        acc[a.category].univers = a.univers
        acc.header[2] = 'univers'
      }
      return acc
    }, {header: ['', 'fund', '']})
  }
  const isGroupedByIssuer = data.share.characteristics.best_worst_esg === 'issuer'
  data.esg = {
    "esg_distribution": esgDistribution(data.share.allocation_analytics.esg_distribution),
    "carbon_footprint": data.share.esg.carbon_footprint,
    "carbon_footprint_difference": data.share.esg.carbon_footprint_difference,
    "carbon_intensity": { type: "hbar", data: data.share.esg.carbon_intensity },
    "carbon_intensity_difference": data.share.esg.carbon_intensity_difference,
    "theme_breakdown": distribution(data.share.allocation_analytics.theme_breakdown?.sort(themeSortFn)),
    "theme_actions": distribution(data.share.allocation_analytics.theme_actions?.sort(themeSortFn)),
    "theme_govies": distribution(data.share.allocation_analytics.theme_govies?.sort(themeSortFn)),
    "theme_credit": distribution(data.share.characteristics.esg_thematik_carbon_hide_benchmark ? data.share.allocation_analytics.theme_credit.map(a => Object.fromEntries(Object.entries(a).filter(([k, _]) => k !== 'univers')))?.sort(themeSortFn) : data.share.allocation_analytics.theme_credit?.sort(themeSortFn)),
    "carbon_distribution":esgDistribution(data.share.allocation_analytics.carbon_distribution),
    "carbon_footprint_by_sector": [['sectors', 'weight_pct', 'carbon_footprint', 'carbon_footprint_universe_diff']]
      .concat(data.share.allocation_analytics.carbon_footprint_by_sector
        .map(v => v.v()))
    ,
    "main_lines_table_esg": isGroupedByIssuer
      ? esgLines(data.share.allocation_analytics.main_lines_table_esg_issuers, ["issuer","weight","score_e","score_s","score_g","score_esg"])
      : esgLines(data.share.allocation_analytics.main_lines_table_esg, ["name","weight","score_e","score_s","score_g","score_esg"]),
    "main_lines_table_esg_cropped": isGroupedByIssuer
      ? esgLines(data.share.allocation_analytics.main_lines_table_esg_issuers_cropped, ["issuer","weight","score_esg_dirr"])
      : esgLines(data.share.allocation_analytics.main_lines_table_esg_cropped, ["name","weight","score_esg_dirr"]),
    "main_lines_table_carbon": isGroupedByIssuer
      ? esgLines(data.share.allocation_analytics.main_lines_table_carbon_issuers, ["issuer","weight","score_carbon_impact"])
      : esgLines(data.share.allocation_analytics.main_lines_table_carbon, ["name","weight","score_carbon_impact"]),
    "main_lines_table_carbon_govies": esgLines(data.share.allocation_analytics.main_lines_table_carbon_govies, ["worst_scores","score_carbon_impact"]),
    "high_carbon": data.share.allocation_analytics.high_carbon,
    "low_carbon": data.share.allocation_analytics.low_carbon,
    "main_green_bonds": data.share.allocation_analytics.main_green_bonds.map(el=>({emetteur: titleize_normal($root.t[el.emetteur]), weight_fund_pct: el.fund, score_esg: format(',2f')(+el.score_esg), score_carbon_impact: format(',2f')(+el.score_carbon_impact)})).sort('-weight_fund_pct'),
    "main_carbon_provider": data.share.allocation_analytics.main_carbon_provider.map(el=>({name:el.name, emissions_evitees: +el.axis.emissions_evitees})).filter(el=>el.emissions_evitees < 0).sort('emissions_evitees'),
    "esg_data_impact": esg_data_impact(data),
    "esg_score_by_pocket": esg_score_by_pocket(data),
    "main_lines_table_esg_credit": esgLines(data.share.allocation_analytics.main_lines_table_esg_credit, ["worst_scores","weight","score_esg"]),
    "main_lines_table_esg_govies": esgLines(data.share.allocation_analytics.main_lines_table_esg_govies, ["worst_scores","weight","score_esg"]),
    "main_lines_table_esg_action": lines(data.share.allocation_analytics.main_lines_table_esg_action, ["worst_scores","weight","score_esg"]),
    "carbon_intensity_action_pocket": data.share.esg.carbon_intensity_action_pocket,
    "carbon_intensity_govies_pocket": data.share.esg.carbon_intensity_govies_pocket,
    "carbon_intensity_credit_pocket": data.share.esg.carbon_intensity_credit_pocket,
    "scoring_climatique_action_pocket": scoringPocket(data.share.esg.scoring_climatique_action_pocket, 'action_pocket'),
    "scoring_climatique_govies_pocket": scoringPocket(data.share.esg.scoring_climatique_govies_pocket, 'govies_pocket'),
    "scoring_climatique_credit_pocket": scoringPocket(data.share.esg.scoring_climatique_credit_pocket, 'credit_pocket'),
    "list_asset_class": distribution(data.share.allocation_analytics.esg_asset_classes),
    "sfdr_breackdown": distribution(data.share.allocation_analytics.sfdr_breackdown.filter(s => s.fund > 0.005)),
    "impact_bonds_breackdown": distribution(data.share.allocation_analytics.impact_bonds_breackdown.sort('-fund')),
    "temperature_data": temperature(data.share.esg.temperature_data),
    "greenfin_repartition": data.share.allocation_analytics.greenfin_repartition,
    "esg_impact_positif":  {type: "hbar", data: data.share.allocation_analytics.esg_impact_positif}
  }

  // HACK TO KEEP ?
  const em = data.graphs.find(v => v?.title === 'emetteur')
  if (em) em.data = data.share.allocation_analytics.main_lines_table_esg.allocations.group('axis.emetteur').map((grp, emetteur) => ({ emetteur, weight: grp.sum('weight_fund') })).v().sort('-weight').map(v => ({ emetteur: v.emetteur, weight: format('.2%')(v.weight) }))

  // HACK TO REMOVE !
  // const mockIndicLeft = false
  // const mockIndicRight = false
  // const mockEsg = false
  // if (mockIndicLeft || mockIndicRight || mockEsg) {
  //    const prod = (
  //       await axios({
  //         url: 'https://corz.deno.dev/https://lafrancaise.nx.digital/dr',
  //         method: 'post',
  //         headers: {
  //           authorization:
  //             'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik9UWXdSVFkwTlRJME5VSTBSVU01TURaRVJqaEZSRUUxUVRsRVFqWkNPVVF4UXpaRk16SXlRdyJ9.eyJodHRwczovLzEwMG0uaW8vYXBwX21ldGFkYXRhIjp7InJvbGUiOiJhZG1pbiJ9LCJodHRwczovLzEwMG0uaW8vdXNlcl9tZXRhZGF0YSI6e30sImh0dHBzOi8vaGFzdXJhLmlvL2p3dC9jbGFpbXMiOnsieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoidXNlciIsIngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsidXNlciIsImFkbWluIl0sIngtaGFzdXJhLXVzZXItaWQiOiJhdXRoMHw1ZWIxMzZmMTY2MzY1MTBiZTlmOGUwMDYifSwibmlja25hbWUiOiJ2YWxlbnRpbi5icmFqb24iLCJuYW1lIjoidmFsZW50aW4uYnJham9uQG5lb3hhbS5jb20iLCJwaWN0dXJlIjoiaHR0cHM6Ly9zLmdyYXZhdGFyLmNvbS9hdmF0YXIvMTYzY2IzNzZiMjQyYzdmNDc2MWY3ZDE5MDVjMzQ4ZmQ_cz00ODAmcj1wZyZkPWh0dHBzJTNBJTJGJTJGY2RuLmF1dGgwLmNvbSUyRmF2YXRhcnMlMkZ2YS5wbmciLCJ1cGRhdGVkX2F0IjoiMjAyMi0xMC0xOFQxMjo0MjoyMi4yNzJaIiwiZW1haWwiOiJ2YWxlbnRpbi5icmFqb25AbmVveGFtLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiaXNzIjoiaHR0cHM6Ly9sZnJhLmV1LmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw1ZWIxMzZmMTY2MzY1MTBiZTlmOGUwMDYiLCJhdWQiOiJaVjdQbzdWamwyaEdQc21Sa2xVU3FheHVZRmNYaWM1diIsImlhdCI6MTY2NjA5Njk0MiwiZXhwIjoxNjY3ODI0OTQyfQ.xkb93Y4gp5wHI7aAb9Hy7ViPLmhAyGFR3qBrJAeThpW3_uYimVsQ27UVVC6LJ6aQxEOoBJJRbNayvBnAhFRgSFZ3Kmc-B_EkxtBQXMmAOlBMh8bXYKosAeFVcUdr6vuD3WhlHv3WDLMNC3_VjYmRV3AllT73l9LXDIfr_F_ywDecAeg7KzSRRjnSJjMlPHGVx_W35IJSq8jvgj4uKQKCzuMId4xD42NPVkrh_I8FN9ddlikE7Y9cqbL605_zgvahDqquO9UCR_OH3qREpqPEcsCuCfSc80tMJGCT9gsPRh01SE0lyK6Uwf-CTvj02Ku35WEa_eO-8Iz5pors2-c4hQ',
  //         },
  //         data: {
  //           variables: {
  //             fund: data.share.fund_url,
  //             share: variables.isinShare,
  //             domain: variables.domain.split('|').last(),
  //             asof: variables.asof,
  //             lang: variables.lang,
  //             screen: $root.screen.path,
  //           },
  //           query: `query DataReportQuery($fund: String, $share: String, $domain: String, $asof: String, $lang: String, $screen: String) {
  //     datareport(fund: $fund share: $share domain: $domain asof: $asof lang: $lang, screen: $screen) {
  //       characteristic {
  //         tables
  //       }
  //       esg {
  //         esg_distribution
  //         carbon_footprint
  //         carbon_footprint_difference
  //         carbon_intensity
  //         carbon_intensity_difference
  //         theme_breakdown
  //         theme_actions
  //         theme_govies
  //         theme_credit
  //         carbon_distribution
  //         carbon_footprint_by_sector
  //         main_lines_table_esg
  //         main_lines_table_esg_cropped
  //         main_lines_table_carbon
  //         main_lines_table_carbon_govies
  //         high_carbon
  //         low_carbon
  //         main_green_bonds
  //         main_carbon_provider
  //         esg_data_impact
  //         esg_score_by_pocket
  //         main_lines_table_esg_action
  //         main_lines_table_esg_credit
  //         main_lines_table_esg_govies
  //         carbon_intensity_action_pocket
  //         carbon_intensity_govies_pocket
  //         carbon_intensity_credit_pocket
  //         scoring_climatique_action_pocket
  //         scoring_climatique_govies_pocket
  //         scoring_climatique_credit_pocket
  //         list_asset_class
  //         sfdr_breackdown
  //         impact_bonds_breackdown
  //         temperature_data
  //       }
  //     }
  //   }`,
  //         },
  //       })
  //     ).data.data.datareport
  //   if (mockIndicLeft) data.tables.performance_table_left = prod.characteristic.tables[data.share.characteristics.performance_table_left]
  //   if (mockIndicRight) data.tables.performance_table_right = prod.characteristic.tables[data.share.characteristics.performance_table_right]
  //   if (mockEsg) data.esg = prod.esg()
  //   window.prod = prod
  // }
  // data.graphs.map(graph => {
  //   if (!graph || graph.title === $root.t['contrib_perf_dirr']) return
  //   graph.data = graph.data.map(v => v.hasOwnProperty('benchmark_relative') ? v : v.filter()).filter(v => v.keys().length)
  // })
  // $root.t.disclaimer_footer_lf_jkc = $root.t.disclaimer_footer_lf_jkc.split('|')[0]
  // $root.t.rating_emetteur_non_signe_dirr___rating_emetteur_bb = "BB" // wait for nx-pack translation clean

  window.data = data

  return data
}

function get_period(domain) {
  const domainArr = domain.split('|')
  const days = (new Date(domainArr[1]) - new Date(domainArr[0])) / 86400000
  if (days >= 4 && days <= 8) return 'weekly'
  if (days >= 9 && days <= 27 && domain.includes('|')) return 'bimonthly'
  if ((days >= 28 && days <= 37) || (/[0-9]{4}-[0-9]{2}/.test(domain) && days < 35)) return 'monthly'
  if ((days >= 89 && days <= 92) || (/[0-9]{4}-[0-9]{2}/.test(domain) && days < 95)) return 'quarterly'
  if (days >= 180 && days <= 185) return 'biannual'
  if (days >= 363 && days <= 366) return 'annual'
  return 'custom'
}

function themeSortFn(a, b) {
  if (a.category.includes('_n_a')) {
    return 1
  }
  if (b.category.includes('_n_a')) {
    return -1
  }
  return b.fund < a.fund ? -1 : b.fund > a.fund ? 1 : b.univers < a.univers ? -1 : b.univers > a.univers ? 1 : 0
}

function esg_data_impact(data) {
  const carbon_footprint_difference = data.share.esg.carbon_footprint_difference
  const impact_data_emissions_avoided = {
    action: 'impact_data_emissions_avoided_actions',
    credit: 'impact_data_emissions_avoided_credit',
    govies: 'impact_data_emissions_avoided_govies',
  }
  const renewable_energy = {
    action: 'renewable_energy_actions',
    credit: 'renewable_energy_credit',
    govies: 'renewable_energy_govies',
  }
  const impact_data_emissions_avoided_key = impact_data_emissions_avoided[data.share.characteristics.thematique_fonds] || 'impact_data_emissions_avoided_key'
  const renewable_energy_key = renewable_energy[data.share.characteristics.thematique_fonds] || 'renewable_energy_key'
  if (data.share.characteristics.display_impact_green_bonds && data.share.characteristics.fund_asset_class === 'fund_asset_class_actions' ) {
    return {
      column1: [
        {
          id: 1,
          icon: 'fr_carbone',
          title: $root.t.carbon_footprint || 'carbon_footprint',
          value: format('.0f')(Math.round(carbon_footprint_difference * 100)),
          unit: '%',
          p1: $root.t.lower || 'lower',
          p2: data.share.characteristics.impact_data_carbon_footprint_fund + ' ' + ($root.t.tco2m || 'tco2m') + ' ' + ($root.t.vs || 'vs') + ' ' + data.share.characteristics.impact_data_carbon_footprint_bench + ' ' + ($root.t.tco2m || 'tco2m') + '*',
        },
        {
          id: 2,
          icon: 'fr_car',
          title: '  ',
          value: data.share.characteristics.impact_data_cars_removed !== undefined ? data.share.characteristics.impact_data_cars_removed.toLocaleString('fr') + ' ' : '-',
          unit: '',
          p1: $root.t.avoided_cars_over_1y || 'avoided_cars_over_1y',
          p2: '',
        },
      ],
      column2: [
        {
          id: 1,
          icon: 'fr_wind',
          title: $root.t[impact_data_emissions_avoided_key] || impact_data_emissions_avoided_key,
          value: data.share.characteristics.impact_data_emissions_avoided !== undefined ? Math.abs(+data.share.characteristics.impact_data_emissions_avoided).toLocaleString('fr') + ' ' : '-',
          unit: $root.t.tco2m || 'tco2m',
          p1: $root.t.avoided || 'avoided',
          p2: '',
        },
        {
          id: 2,
          icon: 'fr_home',
          title: $root.t.energy_savings || 'energy_savings',
          value: data.share.characteristics.impact_data_home_energy_saved !== undefined ? data.share.characteristics.impact_data_home_energy_saved.toLocaleString('fr') + ' ' : '-',
          p1: $root.t.homes_over_1y || 'homes_over_1y',
          p2: '',
        },
        {
          id: 3,
          icon: 'fr_bag',
          title: '',
          value: data.share.characteristics.impact_data_trashbags_recycled !== undefined ? data.share.characteristics.impact_data_trashbags_recycled.toLocaleString('fr') + ' ' : '-',
          unit: '',
          p1: $root.t.recycled_garbage_bags || 'recycled_garbage_bags',
          p2: '',
        },
      ],
      column3: [
        {
          id: 1,
          icon: 'fr_wind_turbine',
          title: '',
          value: data.share.characteristics.impact_data_green_energy_equivalent !== undefined ? Math.abs(data.share.characteristics.impact_data_green_energy_equivalent).toLocaleString('fr') + ' ' : '-',
          unit: 'MWh',
          p1: $root.t[renewable_energy_key] || renewable_energy_key,
          p2: '',
        },
        {
          id: 2,
          icon: 'fr_tree',
          title: '',
          value: data.share.characteristics.impact_data_trees_equivalent !== undefined ? data.share.characteristics.impact_data_trees_equivalent.toLocaleString('fr') + ' ' : '-',
          unit: '',
          p1: $root.t.trees_to_be_planted || 'trees_to_be_planted',
          p2: data.share.characteristics.impact_data_trees_equivalent_bench.toLocaleString('fr') + ' ' + ($root.t.invested_in_universe || 'invested_in_universe'),
        },
      ],
    }
  }
  return {
    column1: [
      {
        id: 1,
        icon: 'fr_carbone',
        title: $root.t.carbon_footprint || 'carbon_footprint',
        value: format('.0f')(Math.round(carbon_footprint_difference * 100)),
        unit: '%',
        p1: $root.t.lower || 'lower',
        p2: data.share.characteristics.impact_data_carbon_footprint_fund + ' ' + ($root.t.tco2m || 'tco2m') + ' ' + ($root.t.vs || 'vs') + ' ' + data.share.characteristics.impact_data_carbon_footprint_bench + ' ' + ($root.t.tco2m || 'tco2m') + '*',
      },
      {
        id: 2,
        icon: 'fr_car',
        title: '  ',
        value: data.share.characteristics.impact_data_cars_removed !== undefined ? data.share.characteristics.impact_data_cars_removed.toLocaleString('fr') + ' ' : '-',
        unit: '',
        p1: $root.t.avoided_cars_over_1y || 'avoided_cars_over_1y',
        p2: '',
      },
    ],
    column2: [
      {
        id: 2,
        icon: 'fr_home',
        title: $root.t.energy_savings || 'energy_savings',
        value: data.share.characteristics.impact_data_home_energy_saved !== undefined ? data.share.characteristics.impact_data_home_energy_saved.toLocaleString('fr') + ' ' : '-',
        p1: $root.t.homes_over_1y || 'homes_over_1y',
        p2: '',
      },
      {
        id: 3,
        icon: 'fr_bag',
        title: '',
        value: data.share.characteristics.impact_data_trashbags_recycled !== undefined ? data.share.characteristics.impact_data_trashbags_recycled.toLocaleString('fr') + ' ' : '-',
        unit: '',
        p1: $root.t.recycled_garbage_bags || 'recycled_garbage_bags',
        p2: '',
      },
    ],
    column3: [
      {
        id: 2,
        icon: 'fr_tree',
        title: '',
        value: data.share.characteristics.impact_data_trees_equivalent !== undefined ? data.share.characteristics.impact_data_trees_equivalent.toLocaleString('fr') + ' ' : '-',
        unit: '',
        p1: $root.t.trees_to_be_planted || 'trees_to_be_planted',
        p2:
          data.share.characteristics.impact_data_trees_equivalent_bench !== undefined
            ? data.share.characteristics.impact_data_trees_equivalent_bench.toLocaleString('fr') + ' ' + ($root.t.invested_in_universe || 'invested_in_universe') 
            : '-',
      },
    ],
  }
}

function esg_score_by_pocket(data) {
  const fund_data = {}
  if (data.share.characteristics.score_esg_coverage_by_assetclass_dirr)
    fund_data.esg_coverage = JSON.parse(data.share.characteristics.score_esg_coverage_by_assetclass_dirr)
      .filter((v, k) => k !== 'other')
      .map((d) => d.fonds)
  if (data.share.characteristics.score_e_by_assetclass_dirr)
    fund_data.score_e = JSON.parse(data.share.characteristics.score_e_by_assetclass_dirr)
      .filter((v, k) => k !== 'other')
      .map((d) => d.fonds)
  if (data.share.characteristics.score_s_by_assetclass_dirr)
    fund_data.score_s = JSON.parse(data.share.characteristics.score_s_by_assetclass_dirr)
      .filter((v, k) => k !== 'other')
      .map((d) => d.fonds)
  if (data.share.characteristics.score_g_by_assetclass_dirr)
    fund_data.score_g = JSON.parse(data.share.characteristics.score_g_by_assetclass_dirr)
      .filter((v, k) => k !== 'other')
      .map((d) => d.fonds)
  if (data.share.characteristics.score_esg_by_assetclass_dirr)
    fund_data.score_esg = JSON.parse(data.share.characteristics.score_esg_by_assetclass_dirr)
      .filter((v, k) => k !== 'other')
      .map((d) => d.fonds)
  if (!fund_data.esg_coverage || !fund_data.score_e || !fund_data.score_s || !fund_data.score_g || !fund_data.score_esg) return []

  const mapping_bench_action = data.share.characteristics.benchmark_poche_action && (mapping[data.share.characteristics.benchmark_poche_action] || {})
  const mapping_bench_credit = data.share.characteristics.benchmark_poche_credit && (mapping[data.share.characteristics.benchmark_poche_credit] || {})
  const mapping_bench_govies = data.share.characteristics.benchmark_poche_obligations && (mapping[data.share.characteristics.benchmark_poche_obligations] || {})

  const columns = [
    '',
    fund_data.esg_coverage.actions ? 'action_pocket' : 'false',
    data.share.characteristics.benchmark_poche_action ? 'univers_action_pocket' : 'false',
    fund_data.esg_coverage.credit ? 'credit_pocket' : 'false',
    data.share.characteristics.benchmark_poche_credit ? 'univers_credit_pocket' : 'false',
    fund_data.esg_coverage.govies ? 'govies_pocket' : 'false',
    data.share.characteristics.benchmark_poche_obligations ? 'univers_govies_pocket' : 'false',
  ].filter((el) => el !== 'false')
  const lines = ['esg_coverage', 'score_e', 'score_s', 'score_g', 'score_esg']
  const mapping_bench_lines = ['score_esg_coverage', 'score_e_dirr', 'score_s_dirr', 'score_g_dirr', 'score_esg_dirr']

  let result = []
  columns.forEach((column) => {
    switch (column) {
      case '':
        lines.forEach((line, line_i) => {
          result[line_i] = [line]
        })
        break
      case 'action_pocket':
        lines.forEach((line, line_i) => {
          result[line_i].push(fund_data[line].actions)
        })
        break
      case 'univers_action_pocket':
        mapping_bench_lines.forEach((line, line_i) => {
          result[line_i].push(mapping_bench_action[line])
        })
        break
      case 'credit_pocket':
        lines.forEach((line, line_i) => {
          result[line_i].push(fund_data[line].credit)
        })
        break
      case 'univers_credit_pocket':
        mapping_bench_lines.forEach((line, line_i) => {
          result[line_i].push(mapping_bench_credit[line])
        })
        break
      case 'govies_pocket':
        lines.forEach((line, line_i) => {
          result[line_i].push(fund_data[line].govies)
        })
        break
      case 'univers_govies_pocket':
        mapping_bench_lines.forEach((line, line_i) => {
          result[line_i].push(mapping_bench_govies[line])
        })
        break
    }
  })
  result = result.map((line, ind) => {
    return ind === 0 ? line.map((el, i) => (i === 0 ? el : format('.2%')(parseFloat(el)))) : line.map((el, i) => (i === 0 ? el : format('.1f')(parseFloat(el))))
  })
  result.unshift(columns)
  return result
}
