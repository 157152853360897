<style scoped>
h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}
h1 .search-filters .autocomplete {
  min-width: 90% !important;
}
.cell-url .link {
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0 0 0 8px;
}
.cell-url .ghost {
  position: absolute;
  right: 0;
  max-width: 30px;
}

.data-report-product-tag {
  padding: 2px 5px;
  margin-left: 8px;
  border-radius: 3px;
  color: black;
  border: 1px solid black;
  font-size: 8px;
}

.mr2 {
  margin-right: 8px;
}

.hidden {
  display: none;
}

.block .spr.spreadsheet-wrapper {
  max-height: 75vh;
}

.isWarning {
  color: #e17315;
}
.isOk {
  color: green;
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="loading")
h1 
  .row
    span(style="margin-right: 8px") {{ t[$root.screen.path] }}
    badge(:count="queries.length")
  .search-filters
    autocomplete.right(
      :data="autocompleteData" 
      :options="{ placeholder: t.search }" 
      :modelValue="activated_filters"
      @update:modelValue="autocomplete_input"
      @keyup.native="search = $event.target.value"
      @focusout.native="search = ''"
    )
subtitle(style="font-style: italic; margin-top: -5px;") {{ t['client-edition-data-reports-subtitle'] }}
.block.expand.data-reports
  spreadsheet.stripped(:data="filtered_queries" :options="{columns: ['name', 'specialtyName', 'url', 'usage', 'actions'], editable: false}")
    template(v-slot:cell-name="s")
      div {{ s.line.name }}
        span(v-if="isNaN(parseInt(s.line.id))" :tt="t.built_in_tag" class="data-report-product-tag") BUILT-IN
    template(v-slot:cell-url="s")
      a.link(:href="s.line.url" target="_blank") {{ s.line.url.replace(/.token=.*/, '') }}
      button.ghost(:tt="t.copy" @click="copy(s.line.url)")
        svg-icon(name="ic_file_copy")
    template(v-slot:cell-actions="s")
      .row
        button.ghost(:tt="isNaN(parseInt(s.line.id)) ? t.view : t.edit" @click="$router.push({ path: $root.appath +  'data-report', query: { id: s.line.id } })")
          svg-icon(:name="isNaN(parseInt(s.line.id)) ? 'pt-icon-eye-open' : 'pt-icon-edit'")
        button.ghost(:tt="t.duplicate" @click="duplicate(s.line)")
          svg-icon(name="ic_file_copy") 
        button.ghost(:tt="t.export" @click="exportDr(s.line)" v-if="!isNaN(parseInt(s.line.id))")
          svg-icon(name="pt-icon-export") 
        button.ghost(:tt="t.run" @click="$router.push({ path: $root.appath + 'data-report-run', query: { id: s.line.id } })")
          svg-icon(name="pt-icon-play")
        button.ghost(:tt="t.delete" @click="confirmRemoveId=s.line.id" v-if="s.line.usage === 0")
          svg-icon(name="pt-icon-trash")
  .row
    button.mr2.primary(@click="createDr") {{ t.create_data_report }}
    span
      button.primary(@click="triggerFileInput") {{ t.import_data_report }}
        input.hidden(type="file" ref="fileInput" accept="application/json" @change="importDr")

  popup(:show="confirmRemoveId")
    template(v-slot:header)
      | {{t["confirmation_remove"]}}
    template(v-slot:content)
      | {{t["text_remove"]}}
    template(v-slot:action)
      button.secondary-action(@click="confirmRemoveId = null") {{t['confirmation_btn_close']}}
      button.main-action(@click="deleteDr(confirmRemoveId)") {{t["confirmation_btn_delete"]}}
</template>

<script>
import Popup from '../../../components/ui/popup.vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import templateService from '../../../services/TemplateService'
import { uploadFile } from '../../builder/lib/assetManagement'
export const additions = { icon: 'pt-icon-graph' }

export default {
  data() {
    return {
      config, // so that it is also available from the vue template
      loading: true,
      queries: [],
      search: '',
      confirmRemoveId: null,
    }
  },
  computed: {
    filtered_queries() {
      const { domain, ...filters } = $root.filters
      return this.queries.filter(f => Object.entries(filters).every(([k, vs]) => vs.some(v => f[k] === v)))
    },

    activated_filters() {
      const params = this.$route.query
      const activeFilters = params.map(filter_value => filter_value.split('|'))
      const filters = []
      Object.entries(activeFilters).forEach(([filterName, filterValues]) => {
        if (filterName === 'year') return
        filterValues.forEach(value => {
          filters.push(this.formatFilterData(filterName, value))
        })
      })
      return filters
    },

    autocompleteData() {
      const filterCriteria = ['name']
      return this.queries.reduce((groupedQueries, q) => {
        filterCriteria.forEach(criterium => {
          if (typeof q[criterium] === 'string') {
            const filterValue = q[criterium].split('.')[0]
            groupedQueries[criterium] = groupedQueries[criterium] || {}
            groupedQueries[criterium][filterValue] = filterValue
          }
        })
        return groupedQueries
      }, {})
    },
  },

  async mounted() {
    this.$router.replace(this.$route.fullPath.split('?')[0])
    await this.init()
  },

  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    async init() {
      this.loading = true
      this.queries = await this.getQueries()
      this.loading = false
    },
    autocomplete_input(event) {
      const events = event.map(v => v.split('.'))
      const groupedEvents = events.group('0')
      const selected_filters = groupedEvents.map(g => g.map('1').join('|'))
      const new_filter_selected = this.$route.query.filter((v, k) => !this.$route.query.keys().includes(k))
      const query = { ...selected_filters, ...new_filter_selected }
      this.$router.push({ query })
    },
    formatFilterData(name, value) {
      return name + '.' + value
    },
    async getQueries() {
      const r = await dataReportService.list()
      const templates = await templateService.listWithTemplateContext()
      return r.map(q => {
        const varUrl = Object.entries({ ...q.variables, token: $root?.profile?.idToken }).reduce((a, [k, v]) => {
          return `${a}${a ? '&' : '?'}${k}=${encodeURI(v)}`
        }, '')
        // LFRA CASE: graphqlEndpoint is the legacy api
        const baseUrl = config.graphqlEndpoint.match(/http:\/\/|https:\/\/|localhost/)
          ? ''
          : `${window.location.protocol}//${window.location.host}`
        // TODO: Remove hardcoded builder-context
        // Add to protect against deletion of a data report that is used by product [builder-context])
        return {
          ...q,
          url: `${baseUrl}${config.graphqlEndpoint}/dr/${q.id}/run${varUrl}`, // actually here we use the REST 'dr' route of our GraphQL API...
          usage: Number.isInteger(q.id) ? templates.filter(t => t.dataReportId === q.id).length : 1,
        }
      })
    },
    createDr() {
      this.$router.push({ path: $root.appath + 'data-report', query: { new: true } })
    },
    async importDr(event) {
      const dataReportUpload = await uploadFile(event)
      if (dataReportUpload.name && dataReportUpload.query) {
        const dataReportCurrent = await dataReportService.getByName(dataReportUpload.name)
        if (dataReportCurrent) {
          // Ask user if we override current dr
          if (confirm($root.t.ifOverrideDataReport)) {
            const updatedDataReport = await dataReportService.update(dataReportCurrent.id, {
              name: dataReportUpload.name,
              query: dataReportUpload.query,
              variables: dataReportUpload.variables || {},
            })
            $root.toast({ description: $root.t.dataReportUpdateSuccess, type: 'success', timeout: 5000 })
            this.$router.push({ path: $root.appath + 'data-report', query: { id: updatedDataReport.id } })
          }
        } else {
          const createdDataReport = await dataReportService.create({
            name: dataReportUpload.name,
            query: dataReportUpload.query,
            variables: dataReportUpload.variables || {},
            specialtyName: dataReportUpload.specialtyName || 'document by share class',
          })
          $root.toast({ description: $root.t.dataReportCreateSuccess, type: 'success', timeout: 5000 })
          this.$router.push({ path: $root.appath + 'data-report', query: { id: createdDataReport.id } })
        }
      } else {
        $root.toast({ description: $root.t.dataReportFormatError, type: 'error', timeout: 5000 })
      }
    },
    async exportDr(reportInfo) {
      const dataReport = await dataReportService.getById(reportInfo.id)
      const dataReportExport = {
        name: dataReport.name || '',
        query: dataReport.query || '',
        variables: dataReport.variables || {},
        specialtyName: dataReport.specialtyName || 'document by share class',
      }
      JSON.stringify(dataReportExport).download(
        `${$root.project}-${dataReport.name}-${new Date().format('YYYY-MM-DD.hhhmmm')}.json`,
      )
      $root.toast({ description: $root.t.dataReportDownloadSuccess, type: 'success', timeout: 5000 })
    },
    async duplicate(reportInfo) {
      const dataReport = await dataReportService.getById(reportInfo.id)
      const newName = prompt(
        'Enter Data Report name',
        `${name.replace(/ \d\d\d\d-\d\d-\d\d \d\d:\d\d/, '')} ${new Date().format('YYYY-MM-DD hh:mm')}`,
      )
      if (newName && newName.length) {
        try {
          const createdDataReport = await dataReportService.create({
            name: newName,
            query: dataReport.query,
            variables: dataReport.variables ? dataReport.variables : {},
            specialtyName: dataReport.specialtyName,
          })
          this.$router.push({ path: $root.appath + 'data-report', query: { id: createdDataReport.id } })
        } catch (error) {
          if (error.message) {
            let displayMessage = error.message
            if (error.message.includes('already exists')) {
              displayMessage = $root.t.dataReportMustBeUnique
            } else if (error.message.includes('must not be null')) {
              displayMessage = $root.t.invalid_values
            }
            $root.toast({ description: displayMessage, type: 'error', timeout: 5000 })
          }
        }
      } else {
        $root.toast({ description: $root.t.missing_mandatory_fields, type: 'error', timeout: 5000 })
      }
    },
    async deleteDr(id) {
      try {
        await dataReportService.remove(id)
      } catch (e) {
        $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        return
      }
      this.queries.splice(
        this.queries.findIndex(q => q.id === id),
        1,
      )
      this.confirmRemoveId = null
      await this.init()
    },
    async copy(url) {
      await navigator.clipboard.writeText(url)
    },
  },
}
</script>
